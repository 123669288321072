<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('menu')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.FORM_TEMPLATE") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-btn
              depressed
              color="primary"
              class="ma-2"
              v-if="formModel.id && !cloneFormTemplate"
              @click="handleCloneFormTemplate"
            >
              Clone
            </v-btn>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
                @change="changeLocale"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.pages"
                v-model="formModel.status"
                :items="statuses.pages.page"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <div>
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('FORM_INPUT_NAMES.add_fields')" active>
                <v-row class="mt-5">
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-if="formModel.translations[selectedLocale.lang]"
                      v-model="formModel.translations[selectedLocale.lang].name"
                      :key="selectedLocale.lang + '-name'"
                      :label="$t('FORM_INPUT_NAMES.contact_form_name')"
                      :rules="nameRules"
                      :error-messages="
                        messages[
                          'translations.' + selectedLocale.lang + '.name'
                        ]
                      "
                      @keyup="
                        messages[
                          'translations.' + selectedLocale.lang + '.name'
                        ] = ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <label for="date_from">{{
                      $t("FORM_INPUT_NAMES.date_from")
                    }}</label>
                    <b-form-datepicker
                      id="date_from"
                      v-model="formModel.date_from"
                      class="mb-2"
                      :locale="selectedLocale.lang"
                      reset-button
                      label-reset-button="Dátum törlése"
                    ></b-form-datepicker>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <label for="date_to">{{
                      $t("FORM_INPUT_NAMES.date_to")
                    }}</label>
                    <b-form-datepicker
                      id="date_to"
                      v-model="formModel.date_to"
                      class="mb-2"
                      :locale="selectedLocale.lang"
                      reset-button
                      label-reset-button="Dátum törlése"
                    ></b-form-datepicker>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      v-if="inputData"
                      v-model="inputData.inputType"
                      :items="inputTypes"
                      :label="$t('FORM_INPUT_NAMES.type') + '*'"
                    >
                      <template v-slot:item="{ item }">
                        {{ $t("FORM_INPUT_NAMES.templateType_" + item) }} -
                        {{ item }}
                      </template>
                      <template v-slot:selection="{ item }">
                        <span class="navi-text">{{
                          $t("FORM_INPUT_NAMES.templateType_" + item)
                        }}</span>
                      </template>
                    </v-select>
                  </v-col>

                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3" md="3">
                    <v-checkbox
                      v-if="inputData && inputData.inputType"
                      v-model="inputData.required"
                      :label="$t('FORM_INPUT_NAMES.required')"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    inputData &&
                    inputData.inputType &&
                    inputData.inputType == 'checkbox'
                  "
                >
                  <v-col cols="12">
                    {{ $t("FORM_INPUT_NAMES.label") + "*" }}
                    <ckeditor
                      v-model="inputData.label"
                      :config="editorConfig"
                      @input="generateNameSlug()"
                    ></ckeditor>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    v-if="
                      inputData &&
                      inputData.inputType &&
                      inputData.inputType != 'checkbox'
                    "
                  >
                    <v-text-field
                      v-model="inputData.label"
                      @keyup="generateNameSlug()"
                      :label="$t('FORM_INPUT_NAMES.label') + '*'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-if="
                        inputData && inputData.inputType && showVariableName
                      "
                      v-model="inputData.name"
                      @keydown.space.prevent
                      :label="$t('FORM_INPUT_NAMES.variable_name') + '*'"
                      :disabled="
                        inputData.name == 'name' || inputData.name == 'email'
                      "
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-if="inputData && inputData.inputType"
                      v-model="inputData.placeholder"
                      :label="$t('FORM_INPUT_NAMES.placeholder')"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <!-- :items="computedTagCollection" -->
                    <v-combobox
                      v-if="
                        inputData &&
                        ['select', 'multiselect'].includes(inputData.inputType)
                      "
                      v-model="inputData.options"
                      :label="$t('FORM_INPUT_NAMES.selector_options')"
                      multiple
                    >
                      <template #selection="{ item }">
                        <v-chip
                          close
                          color="bg-gray-100"
                          @click:close="
                            deleteChipComboBox(item, inputData.options)
                          "
                          >{{ item }}</v-chip
                        >
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      @click="handleAddInputData"
                      :disabled="disableAddButton"
                    >
                      {{ $t("FORMS.add") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  class="box-shadow mt-5 mb-3"
                  v-if="
                    formModel &&
                    formModel.translations[selectedLocale.lang] &&
                    formModel.translations[selectedLocale.lang].input_fields &&
                    formModel.translations[selectedLocale.lang].input_fields
                      .length > 0
                  "
                >
                  <v-col cols="12" sm="12" md="12">
                    <v-row
                      v-if="
                        formModel &&
                        formModel.translations[selectedLocale.lang] &&
                        formModel.translations[selectedLocale.lang].input_fields
                      "
                    >
                      <v-col cols="12">
                        <h5>
                          {{ $t("FORM_INPUT_NAMES.contact_form") }}:
                          {{ formModel.translations[selectedLocale.lang].name }}
                        </h5>

                        <draggable v-model="draggableCards" style="width: 100%">
                          <v-row v-for="(item, i) in inputs" :key="i">
                            <v-col cols="12" sm="11" md="11">
                              <v-text-field
                                v-if="item.inputType == 'text'"
                                :label="item.label"
                              ></v-text-field>

                              <v-checkbox
                                v-if="item.inputType == 'checkbox'"
                                :label="item.label"
                              ></v-checkbox>

                              <v-text-field
                                v-if="item.inputType == 'email'"
                                type="email"
                                :label="item.label"
                              ></v-text-field>
                              <v-textarea
                                :label="item.label"
                                variant="outlined"
                                v-if="item.inputType == 'textarea'"
                              ></v-textarea>

                              <v-select
                                :label="item.label"
                                v-if="item.inputType == 'select'"
                                :items="item.options || []"
                              ></v-select>

                              <v-select
                                :label="item.label"
                                v-if="item.inputType == 'multiselect'"
                                :items="item.options || []"
                                multiple
                              ></v-select>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="1"
                              md="1"
                              style="padding: 3rem 0"
                            >
                              <v-icon
                                v-if="
                                  item.name != 'name' && item.name != 'email'
                                "
                                :disabled="editedItemIndex != null"
                                color="primary"
                                style="
                                  display: block;
                                  font-size: 16px;
                                  float: right;
                                  margin-right: 5px;
                                "
                                small
                                @click="deleteItem(i)"
                              >
                                mdi-delete
                              </v-icon>
                              <v-icon
                                :disabled="editedItemIndex != null"
                                small
                                color="primary"
                                style="
                                  cursor: pointer;
                                  display: block;
                                  font-size: 16px;
                                  float: right;
                                  margin-right: 5px;
                                "
                                @click="handleEditInputData(i, item)"
                              >
                                mdi-pencil
                              </v-icon>
                            </v-col>
                          </v-row>
                        </draggable>
                        <!-- <pre>
                    {{ formModel }}
                  </pre> -->
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="box-shadow mt-5 mb-3">
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="formModel.redirect_page_id"
                      :items="pageCollection"
                      :label="$t('FORM_INPUT_NAMES.redirect')"
                      item-text="name"
                      item-value="id"
                    />
                  </v-col>
                </v-row>
              </b-tab>
              <b-tab :title="$t('FORM_INPUT_NAMES.admin_email_template')">
                <v-row
                  class="mt-5"
                  v-if="
                    formModel &&
                    formModel.translations[selectedLocale.lang] &&
                    formModel.translations[selectedLocale.lang]
                      .email_templates &&
                    formModel.translations[selectedLocale.lang].email_templates
                      .admin
                  "
                >
                  <v-col cols="12" sm="9" md="10">
                    <v-combobox
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.admin.recipients
                      "
                      :items="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.admin.recipients
                      "
                      :label="$t('FORM_INPUT_NAMES.recipients')"
                      multiple
                      chips
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" sm="3" md="2">
                    <v-checkbox
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.admin.status
                      "
                      :label="$t('STATUSES.active')"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.admin.sender
                      "
                      :label="$t('FORM_INPUT_NAMES.sender_name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.admin.subject
                      "
                      :label="$t('FORM_INPUT_NAMES.subject')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.admin.template
                      "
                      :label="$t('FORM_INPUT_NAMES.template')"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </b-tab>
              <b-tab :title="$t('FORM_INPUT_NAMES.users_email_template')">
                <v-row class="mt-5">
                  <v-col cols="12" sm="9" md="10">
                    <v-combobox
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.users.recipients
                      "
                      :items="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.users.recipients
                      "
                      :label="$t('FORM_INPUT_NAMES.recipients')"
                      multiple
                      chips
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="3" md="2">
                    <v-checkbox
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.users.status
                      "
                      :label="$t('STATUSES.active')"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.users.sender
                      "
                      :label="$t('FORM_INPUT_NAMES.sender_name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.users.subject
                      "
                      :label="$t('FORM_INPUT_NAMES.subject')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="
                        formModel.translations[selectedLocale.lang]
                          .email_templates.users.template
                      "
                      :label="$t('FORM_INPUT_NAMES.template')"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </b-tab>
            </b-tabs>
          </div>
        </v-form>

        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="handleCloseDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->

        <v-btn
          v-if="permissionCan('menu')"
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
        <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
        </SnackBarInfoComponent>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./FormTemplates";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";
import draggable from "vuedraggable";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";

const TRANSLATED_ATRIBUTES = ["name"];

// export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
//   {
//     name: "name",
//     label: "Név",
//     placeholder: " név",
//     inputType: "text",
//     value: "",
//     options: [],
//   },
//   {
//     name: "email_cim",
//     label: "Email cím",
//     placeholder: "Email cím",
//     inputType: "email",
//     value: "",
//     options: [],
//   },
// ];

export const INITIAL_CUSTOM_FIELDS = {};

const INITIAL_INPUT_FIELD = {
  name: "",
  // parentClass:'',
  // class:'',
  // id='',
  // type:'',
  label: "",
  placeholder: "",
  inputType: null,
  value: "",
  options: [],
};

const INITIAL_INPUT_FIELDS = [];

export const initialFormData = () => ({
  id: null,
  status: 1,
  name: "",
  date_from: null,
  date_to: null,
  type: null,
  redirect_page_id: null,
  // translations: {},
  translations: {
    hu: {
      name: "aaaaa",
      attachments: [],
      input_fields: [
        {
          name: "name",
          label: "Név",
          placeholder: "Név",
          inputType: "text",
          value: "",
          options: [],
          required: true,
        },
        {
          name: "email",
          label: "Email cím ",
          placeholder: "Email cim",
          inputType: "email",
          value: "",
          options: [],
          required: true,
        },
      ],

      email_templates: {
        admin: {
          status: 1,
          recipients: ["admin@site.hu"],
          sender: "[email]",
          subject: "Az üzenet tárgya",
          template: "Kedves Admin!\n\nÜzeneted érkezett!",
        },

        users: {
          status: 1,
          recipients: ["[email]"],
          sender: "[email]",
          subject: "Az üzenet tárgya",
          template:
            "Kedves [name]!\n Köszönjük, hogy jelentkeztél demóra, hamarosan felvesszük veled a kapcsolatot.\n\nÜdvözlettel: A Synergy. csapata",
        },
      },
    },
    en: {
      name: null,
      attachments: [],
      input_fields: [
        {
          name: "name",
          label: "Name",
          placeholder: "Name",
          inputType: "text",
          value: "",
          options: [],
          required: true,
        },
        {
          name: "email",
          label: "Email address",
          placeholder: "Email",
          inputType: "email",
          value: "",
          options: [],
          required: true,
        },
      ],
    },
    de: {
      name: null,
      attachments: [],
      input_fields: [
        {
          name: "name",
          label: "Name",
          placeholder: "Name",
          inputType: "text",
          value: "",
          options: [],
          required: true,
        },
        {
          name: "email",
          label: "E-Mail Adresse",
          placeholder: "E-Mail Adresse",
          inputType: "email",
          value: "",
          options: [],
          required: true,
        },
      ],
    },
  },
  input_fields: INITIAL_INPUT_FIELDS,
  attachments: [],
  notification_emails: [],
});

export default {
  name: "PageForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: { SnackBarInfoComponent, draggable, DeleteModalDialog },
  mixins: [formModelMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      // initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },

      inputTypes: [
        "text",
        "textarea",
        "email",
        "checkbox",
        "select",
        "multiselect",
      ],

      inputData: Object.assign({}, INITIAL_INPUT_FIELD),
      inputs: [],
      dialogDelete: false,
      itemToDeleteIndex: null,
      editedItemIndex: null,

      cloneFormTemplate: false,
    };
  },
  computed: {
    ...mapGetters(["pageCollection"]),
    disableAddButton() {
      return !this.inputData.inputType || this.inputData.name.length == 0;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.FORM_TEMPLATE");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },

    computedLangItems() {
      return this.languages.map((item) => {
        return { label: item.name, value: item.lang };
      });
    },

    draggableCards: {
      get() {
        return this.inputs;
      },
      set(val) {
        if (!this.editedItemIndex) {
          this.inputs = val;
          this.formModel.translations[this.selectedLocale.lang].input_fields =
            val;
        }
      },
    },
    showVariableName() {
      return this.permissions.find(
        (i) => i.name == "contactForms.setting.update"
      );
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        // this.selectedLocale = i18nService.languages.find((item) => {
        //   return item.lang == i18nService.getActiveLanguage();
        // });
        // this.selectedLocale = i18nService.userLanguages.find((item) => {
        //   return item.lang == i18nService.getUserActiveLanguage();
        // });
        this.formModel = Object.assign({}, initialFormData());
        this.inputs =
          this.formModel.translations[this.selectedLocale.lang].input_fields;
        this.inputData = Object.assign({}, INITIAL_INPUT_FIELD);

        if (value.editedId) {
          this.inputs = [];
          this.loader = true;
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.inputs = [
                ...this.formModel.translations[this.selectedLocale.lang]
                  .input_fields,
              ];
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
              this.$refs.form.resetValidation();
            });
        } else {
          // this.formModel = Object.assign({}, initialFormData());
          // this.inputs =
          //   this.formModel.translations[this.selectedLocale.lang].input_fields;

          this.setTranslatedAttributes();
          /*this.formModel.translations[this.selectedLocale.lang][
            "custom_fields"
          ]["radio"] = "alma";*/
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchPage"]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            let isTranslated = false;
            this.translatedAttributes.forEach((attribute) => {
              if (
                model.translations[langCode] &&
                model.translations[langCode][attribute] &&
                model.translations[langCode][attribute] != ""
              ) {
                isTranslated = true;
              }
            });
            if (isTranslated) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });

          if (Object.keys(translations).length == 0) {
            translations[this.selectedLocale.lang] =
              model.translations[this.selectedLocale.lang];
          }
          model.translations = Object.assign({}, translations);
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              this.cloneFormTemplate = false;
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              this.cloneFormTemplate = false;
              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    initTranslatedCustomFields() {},

    handleAddInputData() {
      // if (this.inputData.required) {
      //   this.inputData.label += " *";
      // }

      if (!this.formModel.translations[this.selectedLocale.lang].input_fields) {
        this.formModel.translations[this.selectedLocale.lang].input_fields = [];
      }
      if (this.editedItemIndex == null) {
        this.formModel.translations[this.selectedLocale.lang].input_fields.push(
          this.inputData
        );
      } else {
        this.formModel.translations[this.selectedLocale.lang].input_fields[
          this.editedItemIndex
        ] = this.inputData;
      }
      this.inputs = [
        ...this.formModel.translations[this.selectedLocale.lang].input_fields,
      ];
      this.inputData = Object.assign({}, INITIAL_INPUT_FIELD);

      this.editedItemIndex = null;
    },

    deleteItem(i) {
      this.itemToDeleteIndex = i;
      this.dialogDelete = true;
    },

    handleCloseDelete() {
      this.itemToDeleteIndex = null;
      this.dialogDelete = false;
    },

    handleDeleteItemConfirm() {
      this.formModel.translations[this.selectedLocale.lang].input_fields.splice(
        this.itemToDeleteIndex,
        1
      );

      this.inputs =
        this.formModel.translations[this.selectedLocale.lang].input_fields;
      this.handleCloseDelete();
    },

    handleEditInputData(index, item) {
      if (this.editedItemIndex == null) {
        this.editedItemIndex = index;
        this.inputData = Object.assign({}, item);
      }
    },

    deleteChipComboBox(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    handleCloneFormTemplate() {
      this.cloneFormTemplate = true;
      this.formModel.translations[this.selectedLocale.lang].name =
        this.formModel.translations[this.selectedLocale.lang].name + "_clone";
      this.formModel.id = null;
    },

    slugify(text) {
      const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
      const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
      const p = new RegExp(a.split("").join("|"), "g");

      return this.removeHtmlTags(text)
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, "_")
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "_")
        .replace(/^-+|-+$/g, "")
        .replace(/-/g, "_");
    },

    removeHtmlTags(str) {
      var div = document.createElement("div");
      div.innerHTML = str;
      return div.textContent || div.innerText || "";
    },

    generateNameSlug() {
      let slug = this.slugify(this.inputData.label);
      if (this.inputData.name != "name" && this.inputData.name != "email") {
        if (
          "input_fields" in
          this.formModel.translations[this.selectedLocale.lang]
        ) {
          let counter = 0;
          let match = false;
          do {
            const filteredArray = this.formModel.translations[
              this.selectedLocale.lang
            ].input_fields.filter((item) => item["name"] === slug);

            if (filteredArray.length > 0) {
              match = true;
              counter++;
              slug = this.slugify(this.inputData.label) + "_" + counter;
            } else {
              match = false;
            }
          } while (match);
        }
        this.inputData.name = slug;
      }
    },
    changeLocale() {
      this.inputs =
        this.formModel.translations[this.selectedLocale.lang].input_fields;
    },
  },

  mounted() {
    this.fetchPage();
    this.inputs =
      this.formModel.translations[this.selectedLocale.lang].input_fields;

    this.setTranslatedAttributes();
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem;
}
</style>
